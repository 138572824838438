"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.scrollHeader = function scrollHeader() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var header = document.querySelector(".header");
    var hamburger = document.querySelector(".hamburger-menu");
    var menu = document.querySelector(".navigation");
    var menuActive = false;
    if (header) {
      window.addEventListener("scroll", function () {
        var scroll = window.scrollY;
        if (scroll > 720) {
          header.classList.add("is-visible");
        } else {
          header.classList.remove("is-visible");
        }
      });
    }
    if (hamburger) {
      hamburger.addEventListener("click", function (e) {
        e.preventDefault();
        console.log("click");
        menuActive = !menuActive;
        if (menuActive) {
          hamburger.classList.add("is-visible");
          menu.classList.add("is-visible");
          document.body.classList.add("no-scroll");
        } else {
          hamburger.classList.remove("is-visible");
          menu.classList.remove("is-visible");
          document.body.classList.remove("no-scroll");
        }
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
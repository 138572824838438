"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.scrollText = function scrollText() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allTextScroll = document.querySelectorAll(".scroll-text-global");
    var allTextScrollFast = document.querySelectorAll(".scroll-text-global-fast");
    var durationPerPixel = 4;
    var durationPerPixelFast = 2;
    //const durationPerPixel = 1;

    if (allTextScroll) {
      allTextScroll.forEach(function (block) {
        var blockDuration = Math.round(block.scrollWidth * durationPerPixel);
        var splitBlock = block.querySelectorAll(".scroll-text");
        splitBlock.forEach(function (e) {
          var contentAdd = "animation-duration:" + blockDuration + "ms";
          e.setAttribute("style", contentAdd);
        });
      });
    }
    if (allTextScrollFast) {
      allTextScrollFast.forEach(function (block) {
        var blockDuration = Math.round(block.scrollWidth * durationPerPixelFast);
        var splitBlock = block.querySelectorAll(".scroll-text");
        splitBlock.forEach(function (e) {
          var contentAdd = "animation-duration:" + blockDuration + "ms";
          e.setAttribute("style", contentAdd);
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.popupMember = function popupMember() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allMemberCard = document.querySelectorAll(".member-image-container");
    var allCrossClose = document.querySelectorAll(".cross-popup");
    if (allMemberCard) {
      allMemberCard.forEach(function (memberCard) {
        memberCard.addEventListener('click', function () {
          var popup = memberCard.querySelector(".team-member-popup");
          popup.classList.add("is-visible");
          document.body.classList.add("no-scroll");
        });
      });
    }
    if (allCrossClose) {
      allCrossClose.forEach(function (cross) {
        var parentElement = cross.closest('.team-member-popup');
        cross.addEventListener('click', function (e) {
          e.stopPropagation();
          document.body.classList.remove("no-scroll");
          parentElement.classList.remove("is-visible");
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
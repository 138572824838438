"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.subMenu = function subMenu() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allSections = document.querySelectorAll('.page-section-about');
    var allNavLinks = document.querySelectorAll('.sub-nav-link');
    console.log(allSections.length);
    var currentSection = allSections.length > 0 ? allSections[0].id : "";
    window.addEventListener('scroll', function () {
      if (allSections) {
        allSections.forEach(function (section) {
          var observer = new IntersectionObserver(function (element) {
            if (element[0].isIntersecting === true) currentSection = section.id;
          }, {
            threshold: [0.4]
          });
          observer.observe(section);
        });
        changeSubMenu(currentSection);
      }
    });
    var changeSubMenu = function changeSubMenu(currentSection) {
      if (allNavLinks) {
        allNavLinks.forEach(function (navLink) {
          var dataId = navLink.dataset.id;
          if (currentSection == dataId) {
            navLink.classList.add("is-active");
          } else {
            navLink.classList.remove("is-active");
          }
        });
      }
    };
    changeSubMenu(currentSection);
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.servicesHome = function servicesHome() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var servicesHome = document.querySelector('.scroll-services');
    if (servicesHome) {
      var flkty = new Flickity(servicesHome, {
        // options
        cellAlign: 'left',
        cellSelector: '.slider-item',
        pageDots: false,
        prevNextButtons: true,
        draggable: true,
        arrowShape: 'M119.502 56.5568L22.1733 56.5568L53.882 88.715C56.4161 91.285 56.4161 95.5025 53.882 98.0725C51.3479 100.642 47.2543 100.642 44.7202 98.0725L1.90057 54.6458C-0.633527 52.0758 -0.633528 47.9242 1.90056 45.3542L44.7202 1.92752C47.2543 -0.642494 51.3479 -0.642495 53.882 1.92752C56.416 4.49754 56.416 8.6491 53.882 11.2191L22.1733 43.3773L119.502 43.3773C123.076 43.3773 126 46.3427 126 49.967C126 53.5914 123.076 56.5568 119.502 56.5568Z'
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
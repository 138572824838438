"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.textGrows = function textGrows() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allGrowUp = document.querySelectorAll(".show-more");
    if (allGrowUp) {
      allGrowUp.forEach(function (e) {
        var parentElement = e.closest('.page-service-text-container');
        e.addEventListener('click', function () {
          console.log(parentElement);
          parentElement.classList.add("is-unroll");
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();